import React from 'react';
import Layout from '@components/Layout';
import Button from '@components/Button';

const Error404 = () => (
    <Layout>
        <div className="pt-32 bg-tag">
            <div className="max-w-6xl px-5 xl:px-0 mx-auto">
                <div className="flex flex-col  justify-center py-12">
                    <h1>404</h1>
                    <p className="text-primary text-2xl">
                        This was not the page you were looking for...
                    </p>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center">
                <h2 className="my-2">Think this page should work?</h2>
                <p className="text-xl mt-2 font-medium  mx-auto max-w-4xl">
                    Let us know by getting in touch below, we'd really appreciate it!
                </p>
                <div className="mt-8 flex flex-col lg:flex-row justify-center items-center ">
                    <a href="mailto:team@elementsoftworks.co.uk">
                        <Button text="Email us" />
                    </a>
                    <Button secondary onClick={() => window?.FB?.CustomerChat?.show(true)} text="Open chat" />
                </div>
            </div>
        </div>
    </Layout>
);

export default Error404;
